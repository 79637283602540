import Home from "pages/home";
import About from "pages/about";
import Services from "pages/services";
import Contact from "pages/contact";
import Events from "pages/events";
import Gallery from "pages/gallery";

export const ROUTES = [
	{
		route: '/',
		Component: Home,
	},
	{
		route: '/about',
		Component: About,
	},
	{
		route: '/services',
		Component: Services,
	},
	{
		route: '/contact',
		Component: Contact,
	},
	{
		route: '/events',
		Component: Events,
	},
	{
		route: '/gallery',
		Component: Gallery,
	},
];