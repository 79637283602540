import React from 'react';
import InnerBanner from 'components/inner-banner';

const Events = () => {
	return (
		<>
			{/* <!-- inner banner --> */}
			<InnerBanner title="Events" />

			<div className="w3l-text-15">
				{/* <!-- text15 block --> */}
				<section id="text15-block">
					<div className="wrapper">
						<div className="text15-max-align">
							<h5 className="text15-heading">Mechanical Works</h5>
							<h6 className="text15-heading">February 15, 2019 - September 22, 2019</h6>
							<div className="even-list">
								<li className="even-lft">
									<a href="#Link">Business</a>
								</li>
								<li className="even-mid">
									<a href="#Link">Financial</a>
								</li>
								<li className="even-bottom">
									<a href="#Link">Investment</a>
								</li>
							</div>
							<a href="blog-single.html">
								<img src={require("../../assets/images/sing_1.jpg")} className="img-responsive" alt="" />
							</a>
							<p className="text15-para">
								It is a long established fact that a reader will be distracted by the readable content of a page when looking at its
								layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to
								using 'Content here, content here', making it look like readable English.It is a long established fact that a reader
								will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is
								that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it
								look like readable English.
							</p>
						</div>
						<div className="text15-max-align mid-sec">
							<h5 className="text15-heading">Fuel & Gas Management</h5>
							<h6 className="text15-heading">March 10, 2019 - September 22, 2019</h6>
							<div className="even-list">
								<li className="even-lft">
									<a href="#Link">Corporate</a>
								</li>
								<li className="even-mid">
									<a href="#Link">Industrial</a>
								</li>
								<li className="even-bottom">
									<a href="#Link">Fashion</a>
								</li>
							</div>
							<a href="blog-single.html">
								<img src={require("../../assets/images/sing_2.jpg")} className="img-responsive" alt="" />
							</a>
							<p className="text15-para">
								It is a long established fact that a reader will be distracted by the readable content of a page when looking at its
								layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to
								using 'Content here, content here', making it look like readable English.It is a long established fact that a reader
								will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is
								that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it
								look like readable English.
							</p>
						</div>
					</div>
				</section>
			</div>
			{/* <!-- text15 block --> */}
		</>
	);
};

export default Events;
