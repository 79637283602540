import React from 'react';
import InnerBanner from 'components/inner-banner';

const Gallery = () => {
	return (
		<>
			{/* <!-- inner banner --> */}
			<InnerBanner title="Gallery" />

			{/* <!-- //covers --> */}
			<section className="w3l-features-8">
				{/* <!-- /features --> */}
				<div className="features">
					<div className="wrapper">
						<div className="fea-gd-vv">
							<div className="float-top">
								<div>
									<img src={require('../../assets/images/e1.jpg')} class="img-responsive" alt="" />
								</div>
							</div>
							<div className="float-top">
								<div>
									<img src={require('../../assets/images/e2.jpg')} class="img-responsive" alt="" />
								</div>
							</div>
							<div className="float-top">
								<div>
									<img src={require('../../assets/images/e3.jpg')} class="img-responsive" alt="" />
								</div>
							</div>
							<div className="float-top">
								<div>
									<img src={require('../../assets/images/e4.jpg')} class="img-responsive" alt="" />
								</div>
							</div>
							<div className="float-top">
								<div>
									<img src={require('../../assets/images/e5.jpg')} class="img-responsive" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //features --> */}
			</section>
		</>
	);
};

export default Gallery;
