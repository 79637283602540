import Routes from 'routes/routes';
import './App.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
