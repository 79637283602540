import React from 'react';
import { Link } from 'react-router-dom';

const InnerBanner = ({ title = '' }) => (
	<section className="w3l-inner-banner-main">
		<div className="about-inner">
			<div className="wrapper">
				<ul className="breadcrumbs-custom-path">
					<h3>{title}</h3>
					<li>
						<Link to="/">
							Home <span className="fa fa-angle-right" aria-hidden="true"></span>
						</Link>
					</li>
					<li className="active">{title}</li>
				</ul>
			</div>
		</div>
	</section>
);

export default InnerBanner;
