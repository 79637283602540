import React from 'react';
import InnerBanner from 'components/inner-banner';

const Services = () => {
	const SERVICES = [
		{
			image: require('../../assets/images/e1.jpg'),
			icon: 'fa fa-chain-broken',
			title: 'Transportation and Freight Management',
			description: `Dedicated fleet management and optimized route planning for cost-effective and timely deliveries.`,
		},
		{
			image: require('../../assets/images/e2.jpg'),
			icon: 'fa fa-plug',
			title: 'Inventory Management',
			description: `Inventory optimization strategies to reduce carrying costs and minimize stock-outs. Demand forecasting and
			analysis to ensure optimal inventory levels. Efficient order processing and replenishment mechanisms to
			maintain seamless supply chain flow.`,
		},
		{
			image: require('../../assets/images/e3.jpg'),
			icon: 'fa fa-database',
			title: 'Technology Solutions',
			description: `Cutting-edge logistics management systems for end-to-end visibility and control. Integration capabilities
			with your existing systems to streamline data exchange. Data analytics and reporting tools to provide
			actionable insights and drive informed decision- making.`,
		},
		{
			image: require('../../assets/images/e4.jpg'),
			icon: 'fa fa-fire-extinguisher',
			title: 'Oil and Gas',
			description: `VMI and WMS integrated raw material warehousing- Under process`,
		},
		{
			image: require('../../assets/images/e5.jpg'),
			icon: 'fa fa-truck',
			title: 'Logistics Services',
			description: `Strict safety and environment regulations`,
		},
		{
			image: require('../../assets/images/g6.jpg'),
			icon: 'fa fa-industry',
			title: 'General Industry',
			description: `Dedicated and shared warehouse and transport solutions`,
		},
	];

	return (
		<>
			{/* <!-- inner banner --> */}
			<InnerBanner title="Services" />

			<section className="w3l-features-8">
				{/* <!-- /features --> */}
				<div className="features">
					<div className="wrapper">
						<div
							className="cwp23-title"
							style={{
								width: '100%',
								paddingBottom: 20,
							}}>
							<h3
								style={{
									maxWidth: '100%',
								}}>
								TMT manages warehouses for OEMs, Tier 2 suppliers to feed components on shift basis requirements. Operating with about
								60000 sq feet area located strategically 10 km off from Hosur to meet the demands of customer in and around Hosur.
							</h3>
						</div>
						<div className="fea-gd-vv">
							{SERVICES.map((service, index) => (
								<div className="float-top">
									<a href="single.html">
										<img src={service.image} className="img-responsive" alt="" />
									</a>
									<div className="float-lt feature-gd">
										<div className="icon">
											{' '}
											<span className={`fa ${service?.icon}`} aria-hidden="true"></span>
										</div>
										<div className="icon-info">
											<h3>
												<a href="single.html">{service.title}</a>{' '}
											</h3>
											<p>{service.description}</p>
										</div>
									</div>
								</div>
							))}
							{/* <div className="float-top">
								<a href="single.html">
									<img src={require('../../assets/images/e1.jpg')} className="img-responsive" alt="" />
								</a>
								<div className="float-lt feature-gd">
									<div className="icon">
										{' '}
										<span className="fa fa-chain-broken" aria-hidden="true"></span>
									</div>
									<div className="icon-info">
										<h3>
											<a href="single.html">Transportation and Freight Management:</a>{' '}
										</h3>
										<p>Dedicated fleet management and optimized route planning for cost-effective and timely deliveries.</p>
									</div>
								</div>
							</div>
							<div className="float-top">
								<a href="single.html">
									<img src={require('../../assets/images/e2.jpg')} className="img-responsive" alt="" />
								</a>
								<div className="float-lt feature-gd">
									<div className="icon">
										{' '}
										<span className="fa fa-plug" aria-hidden="true"></span>
									</div>
									<div className="icon-info">
										<h3>
											<a href="single.html">Inventory Management</a>{' '}
										</h3>
										<p>
											Inventory optimization strategies to reduce carrying costs and minimize stock-outs. Demand forecasting and
											analysis to ensure optimal inventory levels. Efficient order processing and replenishment mechanisms to
											maintain seamless supply chain flow.
										</p>
									</div>
								</div>
							</div>
							<div className="float-top">
								<a href="single.html">
									<img src={require('../../assets/images/e3.jpg')} className="img-responsive" alt="" />
								</a>
								<div className="float-lt feature-gd">
									<div className="icon">
										{' '}
										<span className="fa fa-database" aria-hidden="true"></span>
									</div>
									<div className="icon-info">
										<h3>
											<a href="single.html">Technology Solutions</a>{' '}
										</h3>
										<p>
											Cutting-edge logistics management systems for end-to-end visibility and control. Integration capabilities
											with your existing systems to streamline data exchange. Data analytics and reporting tools to provide
											actionable insights and drive informed decision- making.
										</p>
									</div>
								</div>
							</div>
							<div className="float-top">
								<a href="single.html">
									<img src={require('../../assets/images/e4.jpg')} className="img-responsive" alt="" />
								</a>
								<div className="float-lt feature-gd">
									<div className="icon">
										{' '}
										<span className="fa fa-fire-extinguisher" aria-hidden="true"></span>
									</div>
									<div className="icon-info">
										<h3>
											<a href="single.html">Oil and Gas</a>{' '}
										</h3>
										<p>VMI and WMS integrated raw material warehousing- Under process</p>
									</div>
								</div>
							</div>
							<div className="float-top">
								<a href="single.html">
									<img src={require('../../assets/images/e5.jpg')} className="img-responsive" alt="" />
								</a>
								<div className="float-lt feature-gd">
									<div className="icon">
										{' '}
										<span className="fa fa-truck" aria-hidden="true"></span>
									</div>
									<div className="icon-info">
										<h3>
											<a href="single.html">Logistics Services</a>{' '}
										</h3>
										<p>Strict safety and environment regulations</p>
									</div>
								</div>
							</div>
							<div className="float-top">
								<a href="single.html">
									<img src={require('../../assets/images/g6.jpg')} className="img-responsive" alt="" />
								</a>
								<div className="float-lt feature-gd">
									<div className="icon">
										{' '}
										<span className="fa fa-industry" aria-hidden="true"></span>
									</div>
									<div className="icon-info">
										<h3>
											<a href="single.html">General Industry</a>{' '}
										</h3>
										<p>Dedicated and shared warehouse and transport solutions</p>
									</div>
								</div>
							</div> */}
							{/* <!-- <div className="float-top">
                        <a href="single.html"><img src="../../assets/images/g3.jpg" className="img-responsive" alt=""/></a>
                        <div className="float-lt feature-gd">
                            <div className="icon"> <span className="fa fa-database" aria-hidden="true"></span></div>
                            <div className="icon-info">
                                <h3><a href="single.html">Industrial</a> </h3>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor
                                    incididunt ut labore et. </p>
                            </div>
                        </div>
                    </div>
                    <div className="float-top">
                        <a href="single.html"><img src="../../assets/images/g4.jpg" className="img-responsive" alt=""/></a>
                        <div className="float-lt feature-gd">
                            <div className="icon"> <span className="fa fa-chain-broken" aria-hidden="true"></span></div>
                            <div className="icon-info">
                                <h3><a href="single.html">Automotive</a> </h3>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor
                                    incididunt ut labore et. </p>
                            </div>
                        </div>
                    </div>
                    <div className="float-top">
                        <a href="single.html"><img src="../../assets/images/g5.jpg" className="img-responsive" alt=""/></a>
                        <div className="float-lt feature-gd">
                            <div className="icon"> <span className="fa fa-plug" aria-hidden="true"></span></div>
                            <div className="icon-info">
                                <h3><a href="single.html">Engineering</a> </h3>
                                <p> Lorem ipsum dolor sit amet, consectetur adipisicingelit, sed do eiusmod tempor
                                    incididunt ut labore et. </p>
                            </div>
                        </div>
                    </div> --> */}
						</div>
					</div>
				</div>
				{/* <!-- //features --> */}
			</section>
		</>
	);
};

export default Services;
