import InnerBanner from 'components/inner-banner';
import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
	return (
		<>
			{/* <!-- inner banner --> */}
			<InnerBanner title="About" />

			{/* <!-- Content-with-photo23 block --> */}
			<section className="w3l-content-with-photo-23">
				<div id="cwp23-block">
					<div className="wrapper">
						<div
							className="cwp23-title"
							style={{
								width: '100%',
								paddingBottom: 20,
							}}>
							<h3 style={{ maxWidth: '100%', fontSize: 16, textAlign: 'justify', lineHeight: 'initial', marginBottom: 20 }}>
								WHY TMT?
							</h3>
							<p style={{ textAlign: 'justify', lineHeight: 'initial' }}>
								At TMT – Thiruchendur MuruganThunai Warehousing, we understand that efficient logistics are the backbone of successful
								supply chain management. As a trusted and reliable third-party logistics (3PL) warehouse, we pride ourselves on
								offering comprehensive and tailored warehousing solutions to meet the unique needs of our clients We provide
								end-to-end logistics services that encompass warehousing, transportation, and fulfillment. Our strategically located
								warehouses are equipped with advanced inventory management and Material handling systems, allowing us to streamline
								operations and optimize space utilization while ensuring the utmost security and safety of your products
								<br></br>
								<br></br>
								Founder – Mr M Sripathi Founder and Managing Director a seasoned logistics professional with over 20 years of
								experience in the field of supply chain management. He has a proven track record of successfully establishing and
								growing 3PL operations for various global companies like Schaeffler India Limited. Sripathi’sexpertise lies in
								strategic planning, operational optimization, and customer relationship management. Mr.Sripathi Founded and led a
								successful 3PL company, overseeing all aspects of the business from inception to expansion, achieving a good
								year-over-year revenue growth.
								<br></br> <br></br>• Implemented solutions to streamline warehouse operations, resulting in significant cost savings
								and increased efficiency.<br></br> <br></br>• Built and nurtured long-term partnerships with key clients, resulting in
								a high rate of customer retention and repeat business.<br></br> <br></br>• Developed and executed appropriate
								strategies to expand the company's service offerings, including value-added services such as kitting, labeling, and
								customization, resulting in increased market share and competitive advantage.<br></br> <br></br>• Led cross-functional
								teams to drive process improvements, implement best practices, and achieve operational excellence, resulting in
								improved service levels and customer satisfaction.<br></br> <br></br>
							</p>
						</div>
						<div
							className="cwp23-title"
							style={{
								width: '100%',
								paddingBottom: 20,
							}}>
							<h3
								style={{
									maxWidth: '100%',
									fontSize: 16,
									textAlign: 'justify',
									lineHeight: 'initial',
									marginBottom: 20,
								}}>
								Why Choose Us?
							</h3>
							<p style={{ textAlign: 'justify', lineHeight: 'initial' }}>
								<bold>Scalability and Flexibility:</bold>
								<br />
								Our services are scalable, allowing you to adjust capacity based on your business needs. We offer flexible solutions
								to accommodate fluctuations in demand and seasonality. <br />
								<br />
								<bold>Cost Efficiency:</bold> <br />
								By outsourcing your logistics to us, you can reduce overhead costs associated with warehousing, transportation, and
								staffing. We leverage our industry expertise and network to negotiate favorable rates with carriers and suppliers.{' '}
								<br />
								<br />
								<bold>Focus on Customer Satisfaction:</bold> Our customer-centric approach means we prioritize your needs and strive
								for excellence in service delivery. We provide personalized solutions and maintain open communication throughout the
								partnership. Partner with TMT for seamless supply chain management and enhanced operational efficiency. Contact us
								today to discuss your logistics requirements and explore how our 3PL solutions can drive your business success.
								<br /> <br />
								<bold>Awards and Events:</bold> TMT has been honored with several prestigious awards in the logistics industry,
								acknowledging our excellence in providing 3PL solutions. We have received recognition for our outstanding customer
								service, innovative approaches, and commitment to operational excellence. Our Principals M/s Schaeffler India Ltd
								conducted a Vendor meet wherein TMT has been awarded as Besting Supporting partner for the year 2022. These accolades
								demonstrate our dedication to delivering exceptional logistics services and setting industry standards. <br />
								Industry Events and Conferences: We actively participate in industry events and conferences to stay updated with the
								latest trends, technologies, and best practices in logistics and supply chain management. Our team regularly attends
								seminars, trade shows, and exhibitions, allowing us to network with industry experts and foster valuable partnerships.
								By staying involved in industry events, we ensure that our services are always aligned with the evolving needs and
								expectations of our clients.
							</p>
						</div>
						<div className="d-grid cwp23-content">
							<div className="cwp23-img">
								<img
									src={require('../../assets/images/e2.jpg')}
									className="img-responsive"
									alt=""
									style={{
										height: '100%',
										objectFit: 'cover',
									}}
								/>
							</div>
							<div className="cwp23-text">
								<div className="cwp23-title">
									<h3>Mission & Vision</h3>
								</div>
								<div
									className="cwp23-text-cols"
									style={{
										gridTemplateColumns: '1fr',
									}}>
									{/* <!-- <div className="column">
                                <h4>History</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div> --> */}
									<div>
										<h4>Mission</h4>
										<p style={{ textAlign: 'justify', lineHeight: 'initial' }}>
											Our mission is to provide superior and comprehensive logistics solutions to our clients, helping them
											optimize their supply chain operations and achieve their business goals. We will leverage our expertise,
											state-of-the-art technology, and committed team to deliver exceptional warehousing, distribution, and
											value-added services. We are dedicated to building long-term partnerships with our clients, based on
											trust, integrity, and mutual success
										</p>
									</div>
									<div className="column">
										<h4>Vision</h4>
										<p style={{ textAlign: 'justify', lineHeight: 'initial' }}>
											To be the leading 3PL (Third-Party Logistics) warehouse that sets the benchmark for excellence in
											logistics and supply chain management. We aim to be recognized as a trusted partner by our clients, known
											for our reliability, efficiency, and customer-centric approach
										</p>
									</div>
									{/* <!-- <div className="column">
                                <h4>Strategy</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div>
                            <div className="column">
                                <h4>Machine</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div> --> */}
								</div>
							</div>
						</div>
						<div className="d-grid cwp23-content" style={{ marginTop: 20 }}>
							<div className="cwp23-text">
								<div className="cwp23-title">
									<h3>Our Services</h3>
								</div>
								<div
									className="cwp23-text-cols"
									style={{
										gridTemplateColumns: '1fr',
									}}>
									{/* <!-- <div className="column">
                                <h4>History</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div> --> */}
									<div>
										<h4>Warehousing and Distribution:</h4>
										<p style={{ textAlign: 'justify', lineHeight: 'initial' }}>
											TMT manages warehouses for OEMs, Tier 2 suppliers to feed components on shift basis requirements.
											Operating with about 80000 sq. feet area located strategically 10 km off from Hosur to meet the demands of
											customer in and around Hosur.<br></br> <br></br>• Our operations cover regular audits, safety drills and
											trainings for our staff at various levels. <br></br>• Management of warehousing for Imported parts and raw
											materials
											<br></br>• Value added services: Transferring to returnable bins, labeling and packing <br></br>• VMI and
											WMS integrated raw material warehousing- Under process <br></br>• Strict safety and environment
											regulations <br></br>• Dedicated and shared warehouse and transport solutions Visibility and Reporting:.
											We provide comprehensive reporting and analytics to help you make data-driven decisions and optimize your
											supply chain operations
										</p>
										<div
											style={{
												marginTop: 10,
											}}>
											<Link
												style={{
													textDecoration: 'underline',
												}}
												to="/services">
												Read More
											</Link>
										</div>
									</div>

									{/* <!-- <div className="column">
                                <h4>Strategy</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div>
                            <div className="column">
                                <h4>Machine</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                            </div> --> */}
								</div>
							</div>
							<div className="cwp23-img">
								<img
									src={require('../../assets/images/e1.jpg')}
									className="img-responsive"
									alt=""
									style={{
										height: '100%',
										objectFit: 'cover',
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- Content-with-photo23 block --> */}
			<section className="w3l-features">
				<div className="grid top-bottom">
					<div className="wrapper">
						<div className="middle-section grid-column text-center">
							<div className="three-grids-columns">
								<span className="fa fa-lightbulb-o"></span>
								<h4>Who We Are</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
							</div>
							<div className="three-grids-columns">
								<span className="fa fa-shirtsinbulk"></span>
								<h4>Our Security</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
							</div>
							<div className="three-grids-columns">
								<span className="fa fa-user-secret"></span>
								<h4>Our Process</h4>
								<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <!-- teams 32 block --> */}
			<section className="w3l-teams-32-main">
				<div className="teams-32">
					<div className="wrapper">
						<div className="d-grid grid-col-4 align-center main-contteam-32">
							<div className="team-main-19">
								<div className="column-19">
									<a href="about#">
										<img className="img-responsive" src={require('../../assets/images/te-1.jpg')} alt=" " />
									</a>
								</div>
								<div className="about#">
									<h6>
										<a href="index.html" className="title-team-32">
											M Sripathi
										</a>
									</h6>
									<p className="sm-text-32">Founder</p>
									{/* <p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p> */}
									<div className="buttons-teams">
										<Link to="/">
											<span className="fa fa-facebook"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-twitter"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-instagram"></span>
										</Link>
									</div>
								</div>
							</div>
							{/* <div className="team-main-19">
								<div className="column-19">
									<a href="team-single.html">
										<img className="img-responsive" src={require('../../assets/images/te-2.jpg')} alt=" " />
									</a>
								</div>
								<div className="right-team-9">
									<h6>
										<a href="team-single.html" className="title-team-32">
											Martin Guptil
										</a>
									</h6>
									<p className="sm-text-32">General Manager</p>
									<p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
									<div className="buttons-teams">
										<Link to="/">
											<span className="fa fa-facebook"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-twitter"></span>
										</Link>
										<Link to="/">
											<span className="fa fa-instagram"></span>
										</Link>
									</div>
								</div>
							</div> */}
							{/* <!-- <div className="team-main-19">
                        <div className="column-19">
                            <a href="team-single.html"><img className="img-responsive" src="assets/images/te-3.jpg"
                                    alt=" "></a>
                        </div>
                        <div className="right-team-9">
                            <h6><a href="team-single.html" className="title-team-32">Steve Smith</a></h6>
                            <p className="sm-text-32">Developer</p>
                            <p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit</p>
                            <div className="buttons-teams">
                                <a href="#"><span className="fa fa-facebook"></span></a>
                                <a href="#"><span className="fa fa-twitter"></span></a>
                                <a href="#"><span className="fa fa-instagram"></span></a>
                            </div>
                        </div>
                    </div>
                    <div className="team-main-19">
                        <div className="column-19">
                            <a href="team-single.html"><img className="img-responsive" src="assets/images/te-4.jpg"
                                    alt=" "></a>
                        </div>
                        <div className="right-team-9">
                            <h6><a href="team-single.html" className="title-team-32">David Warner</a></h6>
                            <p className="sm-text-32">Manager</p>
                            <p className="sub-paragraph midd-para-team">Lorem ipsum dolor sit amet, consectetur adipisicing
                                elit</p>
                            <div className="buttons-teams">
                                <a href="#"><span className="fa fa-facebook"></span></a>
                                <a href="#"><span className="fa fa-twitter"></span></a>
                                <a href="#"><span className="fa fa-instagram"></span></a>
                            </div>
                        </div>
                    </div> --> */}
						</div>
					</div>
				</div>
			</section>
			{/* <!-- //teams 32 block --> */}
			<section className="w3l-content-with-photo-6">
				{/* <!-- /content-with-photo --> */}
				{/* <!-- <div className="content-with-photo">
            <div className="float-lt photo-inn">
                <h2>Consectetur Adipisicing elit</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.Lorem ipsum dolor sit
                    amet, consectetur adipisicing elit, sed do eiusmod tempor </p>
                <div className="content-w-grids">
                    <div className="sub-grid">
                        <h3>Parts and systems</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                    </div>
                    <div className="sub-grid">
                        <h3>Maintenance</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>

                    </div>
                </div>
            </div>
            <div className="float-rt photo-img">
                <img src="assets/images/e5.jpg" className="img-responsive" alt="vv">
            </div>

        </div> --> */}

				{/* <!-- /content-with-photo --> */}
			</section>
		</>
	);
};

export default About;
